.header-bg {
  background: #7697F8;
}
.table td.fit, 
.table th.fit {
    white-space: nowrap;
    width: 1%;
}

.align-right {
  text-align: right;
}

.navbar > .container, .navbar > .container-fluid {
  &.auth-header {
    justify-content: center;
  }
}